import React, { createContext, useState, useCallback } from 'react';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toastSuccess, setToastSuccess] = useState(false);

  const triggerToastSuccess = useCallback(() => {
    setToastSuccess(prev => !prev); // Alterna o estado para indicar um novo toast de sucesso
  }, []);

  return (
    <ToastContext.Provider value={{ toastSuccess, triggerToastSuccess }}>
      {children}
    </ToastContext.Provider>
  );
};
