/* eslint-disable */
import React from 'react';
import App from 'src/App';
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import { store, persistor } from "./redux/store";
import { HelmetProvider } from 'react-helmet-async';
import { PersistGate } from "redux-persist/integration/react";
import { SidebarProvider } from 'src/contexts/SidebarContext';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <HelmetProvider>
        <SidebarProvider>
          <BrowserRouter>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </BrowserRouter>
        </SidebarProvider>
      </HelmetProvider>
    </React.StrictMode>
  </Provider>
);
