import router from 'src/router';
import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import ThemeProvider from './theme/ThemeProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ToastProvider } from './layouts/PageTemplate/ToastContext';

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <ToastProvider>
          {content}
          <ToastContainer autoClose={1500} />
        </ToastProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
