import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentStageCotacao: "itens",
  userInfo: [],
  products: [],
  frete: 0,
  itensFrete: [],
};

export const orebiSlice = createSlice({
  name: "orebi",
  initialState,
  reducers: {
    setCurrentStageCotacao: (state, action) => {
      state.currentStageCotacao = action.payload;
    },
    addToCart: (state, action) => {
      const item = state.products.find(
        (item) => item.id_Produto === action.payload.id_Produto
      );
      if (item) {
        item.quantidade += action.payload.quantidade;
        item.valor_total += action.payload.valor_total
      } else {
        state.products.push(action.payload);
      }
    },
    increaseQuantity: (state, action) => {
      const item = state.products.find(
        (item) => item.id_Produto === action.payload.id_Produto
      );
      if (item) {
        item.quantidade++;
        item.valor_total += (item.valor + item.markup)
      }
    },
    drecreaseQuantity: (state, action) => {
      const item = state.products.find(
        (item) => item.id_Produto === action.payload.id_Produto
      );
      if (item.quantidade === 1) {
        item.quantidade = 1;
        item.valor_total = (item.valor + item.markup)
      } else {
        item.quantidade--;
        item.valor_total -= (item.valor + item.markup)
      }
    },
    deleteItem: (state, action) => {
      state.products = state.products.filter(
        (item) => item.id_Produto !== action.payload.id_Produto
      );
      if (state.products.length === 0) {
        state.frete = 0;
        state.itensFrete = [];
      }
    },
    resetCart: (state) => {
      state.products = [];
      state.frete = 0;
      state.itensFrete = []; // Limpa o array de itensFrete quando o frete for 0
    },
    setFrete: (state, action) => {
      state.frete = action.payload;
      if (action.payload === 0) {
        state.itensFrete = []; // Limpa o array de itensFrete quando o frete for 0
      }
    },
    setItensFrete: (state, action) => {
      state.itensFrete = [action.payload];
    },
  },
});

export const {
  addToCart,
  increaseQuantity,
  drecreaseQuantity,
  deleteItem,
  resetCart,
  setFrete,
  setItensFrete,
  setCurrentStageCotacao
} = orebiSlice.actions;
export default orebiSlice.reducer;
