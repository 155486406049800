/* eslint-disable */
import { useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { AutoGraph,  House, Paid } from '@mui/icons-material';
import { Box, List, styled, Button, ListItem } from '@mui/material';
import Icon1 from './icons/1.png';
import Icon2 from './icons/2.png';
import Icon3 from './icons/3.png';
import Icon4 from './icons/4.png';
import Icon5 from './icons/5.png';
import Icon6 from './icons/6.png';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: ${theme.spacing(1)};
      & > .MuiList-root {
        padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
      }
    }
  `
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      .MuiListItem-root {
        padding: 1px 0;
        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[100]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
          background-color: #070d27;
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  `
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  const roles = JSON.parse(localStorage.getItem('roles')) || [];

  const menuItems = [
    { to: '/Home', label: 'Home', icon: <House />, roles: ['ROLE_USER', 'ROLE_MODERATOR', 'ROLE_ADMIN'] }, // Futuro Anuncios
    { to: '/Produtos', label: 'Produtos', icon: <img src={Icon1} alt="Produtos Icon" style={{ width: '20px', height: '20px' }} />, roles: ['ROLE_MODERATOR', 'ROLE_ADMIN'] },
    { to: '/Cotacao', label: 'Cotações', icon: <Paid />, roles: ['ROLE_USER', 'ROLE_MODERATOR', 'ROLE_ADMIN'] },
    { to: '/Cart', label: 'Nova Cotação', icon: <img src={Icon2} alt="Nova Cotação Icon" style={{ width: '20px', height: '20px' }} />, roles: ['ROLE_USER', 'ROLE_MODERATOR', 'ROLE_ADMIN'] },
    { to: '/Cliente', label: 'Clientes', icon: <img src={Icon3} alt="Clientes Icon" style={{ width: '20px', height: '20px' }} />, roles: ['ROLE_MODERATOR', 'ROLE_ADMIN'] },
    { to: '/Fornecedor', label: 'Fornecedores', icon: <img src={Icon4} alt="Fornecedores Icon" style={{ width: '20px', height: '20px' }} />, roles: ['ROLE_MODERATOR', 'ROLE_ADMIN'] },
    { to: '/Usuarios', label: 'Usuários', icon: <img src={Icon5} alt="Usuários Icon" style={{ width: '20px', height: '20px' }} />, roles: ['ROLE_ADMIN'] },
    { to: '/Anuncios', label: 'Anuncios', icon: <AutoGraph />, roles: ['ROLE_ADMIN'] }, // Gestor de Anuncios
    { to: '/Login', label: 'Sair', icon: <img src={Icon6} alt="Sair Icon" style={{ width: '20px', height: '20px' }} />, roles: ['ROLE_USER', 'ROLE_MODERATOR', 'ROLE_ADMIN', 'NO_ROLE'] }
  ];

  const filteredMenuItems = menuItems.filter(item => 
    roles.some(role => item.roles.includes(role))
  );

  return (
    <MenuWrapper>
      <List component="div">
        {filteredMenuItems.map((item, index) => (
          <SubMenuWrapper key={index}>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={item.to}
                  startIcon={item.icon}
                >
                  {item.label}
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        ))}
      </List>
    </MenuWrapper>
  );
}

export default SidebarMenu;
