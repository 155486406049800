import { Suspense, lazy } from 'react';
import BaseLayout from 'src/layouts/BaseLayout';
import SidebarLayout from 'src/layouts/SidebarLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';

  const Loader = (Component) => (props) =>(
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

  const Login = Loader(lazy(() => import('src/pages/login')));
  const Recuperação = Loader(lazy(() => import('src/pages/recuperacao')));

  const Home = Loader(lazy(() => import('src/pages/home')));
  const Cart = Loader(lazy(() => import('src/pages/cart')));
  const User = Loader(lazy(() => import('src/pages/usuario')));
  const Cliente = Loader(lazy(() => import('src/pages/cliente')));
  const Produtos = Loader(lazy(() => import('src/pages/produtos')));
  const Cotacao = Loader(lazy(() => import('src/pages/cotacao')));
  const Impressao = Loader(lazy(() => import('src/pages/impressao')));
  const Status404 = Loader(lazy(() => import('src/pages/Status404')));
  const Fornecedor = Loader(lazy(() => import('src/pages/fornecedor')));
  const Anuncios = Loader(lazy(() => import('src/pages/anuncios')));

  const routes = [
    {
      path: '',
      element: <BaseLayout />,
      children: [
        { path: '/',            element: <Login />  },
        { path: '*',            element: <Status404 /> },
        { path: '404',          element: <Status404 /> },
        { path: 'login',        element: <Login /> },
        { path: 'recuperacao',  element: <Recuperação /> },
      ],
    },
    {
      path: '',
      /*  element: <BaseLayout />, */
      children: [
        { path: "/Impressao/:id", element: <Impressao />},
      ],
    },
    {
      path: '/',
      element: <SidebarLayout />,
      children: [
        { path: 'Home',             element: <Home /> },
        { path: 'Cotacao',          element: <Cotacao /> },
        { path: 'Produtos',         element: <Produtos /> },
        { path: 'Cliente',          element: <Cliente /> },
        { path: 'Fornecedor',       element: <Fornecedor /> },
        { path: 'Usuarios',         element: <User /> },
        { path: 'Cart',             element: <Cart /> },
        { path: 'Anuncios',         element: <Anuncios /> },
      ]
    },
  ];

export default routes;
