import logoImage from "../../assets/images/logo.png"

function Logo() {
  return (
    <img
      src={logoImage}
      alt="Logo"
      style={{ height: '50px', width: 'auto', borderRadius: 'inherit' }}
    />
  );
}

export default Logo;
